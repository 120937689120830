<template>
  <div class="home_warp">
    <!-- 顶部导航 -->
    <titleNav title="灵活就业参保须知" :left-arrow="true" left-text="" />
    <!-- 内容 -->
    <div class="toptiti">灵活就业参保须知</div>
    <div class="title">
      <img src="@/assets/images/insur/形状 11@2x.png" alt="" />服务内容
    </div>
    <div class="com" style="margin-top: 5px">
      为灵活就业人员代理社保（养老和医疗）
    </div>
    <div class="title">
      <img src="@/assets/images/insur/kehu@2x.png" alt="" />服务对象
    </div>
    <div style="margin-top: 5px">
      <div class="fwdx">
        <img class="img" src="@/assets/images/insur/形状 6@2x.png" alt="" />
        <div class="div">无雇工的个体工商户；</div>
      </div>
      <div class="fwdx">
        <img class="img" src="@/assets/images/insur/形状 6@2x.png" alt="" />
        <div class="div">有工作单位但是没有缴纳社保的人员；</div>
      </div>
      <div class="fwdx">
        <img class="img" src="@/assets/images/insur/形状 6@2x.png" alt="" />
        <div class="div">
          与用人单位解除或终止劳动关系的失业人员、辞职人员、自谋职业人员。
        </div>
      </div>
    </div>

    <div class="title">
      <img src="@/assets/images/insur/wodeliucheng@2x.png" alt="" />办理流程
    </div>
    <div style="margin-top: 5px">
      <div class="fwdx">
        <div class="shz">1</div>
        <div class="div">填写信息提交订单；</div>
      </div>
      <div class="fwdx">
        <div class="shz">2</div>
        <div class="div">收到订单专职客服对接；</div>
      </div>
      <div class="fwdx">
        <div class="shz">3</div>
        <div class="div">专职人员业务申报；</div>
      </div>
      <div class="fwdx">
        <div class="shz">4</div>
        <div class="div">申报成功后专职客服及时反馈。</div>
      </div>
    </div>

    <div class="title">
      <img src="@/assets/images/insur/tishi@2x.png" alt="" />温馨提示
    </div>
    <div class="wxts">
      灵活就业人员，2022年缴纳养老保险费有困难的，可自愿暂缓缴费，2022年未缴费月度可于2023年底前进行补缴，缴费基数在2023年个人缴费基数上下限范围内自主选择，缴费年限累计计算
    </div>

    <div class="title">
      <img src="@/assets/images/insur/goumai@2x.png" alt="" />购买须知
    </div>
    <div style="margin-top: 5px">
      <div class="fwdx">
        <div class="shzs">1.</div>
        <div class="div">
          服务费按年收取，从开户申请日期起，预交一年，中途中断概不退款，服务费到期后预收取下年度服务费。请到期前缴纳下年度服务费。
        </div>
      </div>
      <div class="fwdx">
        <div class="shzs">2.</div>
        <div class="div">
          如需停止灵活就业人员代理社保（养老或医疗）业务，请提前20个工作日申请。
        </div>
      </div>
      <div class="fwdx">
        <div class="shzs">3.</div>
        <div class="div">
          因自身原因未及时办理相关中断手续的，由此产生的服务费用，全权自行负责。
        </div>
      </div>
    </div>

    <div class="bohao" @click="callPhone(400-8877-827)">
      <div>如有疑问请拨打服务热线</div>
      <div>400-8877-827</div>
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
// import floatBtn from "@/components/floatBtn.vue";
import {
  Button,
  Dialog,
  Icon,
  Toast,
  Field,
  Checkbox,
  CheckboxGroup,
} from "vant";
import { banner, price, indexs } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      checked: true,
      tel: "",
      sms: "",
    };
  },
  created() {
    window.scrollTo(0,0);
  },
  mounted() {},
  methods: {
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
  },
};
</script>

<style scoped lang="scss">
.home_warp {
  //   height: 100%;
  background: #ffffff;
  background-color: #ffffff;
  position: relative;
  padding: 10px;
  .toptiti {
    text-align: center;
    color: #0097ff;
    font-size: 24px;
    font-weight: 600;
    font-family: DFPZongYiW9-GB;
    padding-bottom: 10px;
  }
  .title {
    margin-top: 5px;
    background: linear-gradient(-70deg, #28aafc, #1685d6);
    display: inline-block;
    color: #fff;
    font-size: 15px;
    padding: 3px 10px;
    border-radius: 12px;
    padding-top: 1px;
    img {
      width: 15px;
      height: 17px;
      margin-right: 5px;
      transform: translateY(3px);
    }
  }
  .com {
    font-size: 15px;
    line-height: 30px;
  }
  .fwdx {
    font-size: 15px;
    line-height: 30px;
    // line-height: 40px;
    .img {
      width: 18px;
      height: 18px;
      display: inline-block;
      transform: translateY(5px);
      margin-right: 5px;
      vertical-align: top;
    }
    .shz {
      display: inline-block;
      font-size: 12px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: linear-gradient(-70deg, #28aafc, #1685d6);
      color: #fff;
      text-align: center;
      line-height: 20px;
      margin-right: 5px;
    }
    .shzs {
      display: inline-block;
      font-size: 15px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      vertical-align: top;
    }
    .div {
      width: calc(100% - 23px);
      display: inline-block;
    }
  }
  .wxts {
    font-size: 15px;
    line-height: 30px;
    text-indent: 30px;
    margin-top: 5px;
  }
  .bohao {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    background-color: #1788d9;
    padding: 5px 0;
    border-radius: 5px;
    margin-top: 10px;
  }
}
</style>
